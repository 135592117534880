import { storyblokEditable } from "@storyblok/react/rsc";
import { useEffect, useState } from "react";

import type {
  CampsiteStoryblok,
  FeaturedCampsitesStoryblok,
} from "@/component-types-sb";
import { Card } from "@/components/layout/Card";
import { BodyText, Divider, H2 } from "@/components/typography";

type FeaturedCampsitesPops = {
  blok: FeaturedCampsitesStoryblok;
};

const FeaturedCampsites = ({ blok }: FeaturedCampsitesPops) => {
  const [campsites, setCampsites] = useState<CampsiteStoryblok[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/api/campsites");
      const json = await res.json();
      setCampsites(json);
    };
    fetchData();
  }, []);

  const hasOffer = (current: CampsiteStoryblok) =>
    campsites.some(
      (campsite) => campsite.uuid == current.uuid && campsite.offers > 0
    );

  return (
    <section
      className="py-8 mx-2 lg:mx-0 text-center"
      {...storyblokEditable(blok)}
    >
      <H2 colorScheme="primary">{blok.headline}</H2>
      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.text}
      </BodyText>

      <Divider />

      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        {blok.campsites?.map((nestedBlok: any, index) => (
          <Card
            key={nestedBlok.id}
            size={index === 0 ? "large" : "normal"}
            image={nestedBlok.content?.image}
            href={nestedBlok.full_slug}
            name={nestedBlok.name}
            text={nestedBlok.content?.lead}
            buttonLabel="Entdecken"
            hasOffer={hasOffer(nestedBlok)}
          />
        ))}
      </div>
    </section>
  );
};

FeaturedCampsites.blockName = "featured_campsites";

export default FeaturedCampsites;
