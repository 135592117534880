"use client";

import { storyblokInit, apiPlugin, RichTextSchema } from "@storyblok/react/rsc";
import { ReactElement } from "react";
import { cloneDeep } from "lodash";

import Accommodation from "./pages/Accommodation";
import AccommodationOverview from "./pages/AccommodationOverview";
import Accordion from "./landingpage/Accordion";
import AccordionItem from "./landingpage/AccordionItem";
import AwardItem from "./landingpage/AwardItem";
import IiQCheck from "./landingpage/IiqCheck";
import Awards from "./landingpage/Awards";
import Reviews from "./landingpage/Reviews";
import Backlink from "./Backlink";
import Banner from "./header/Banner";
import BannerItem from "./header/BannerItem";
import BodyText from "./typography/BodyText";
import Booking from "./pages/Booking";
import Box from "./landingpage/Box";
import BoxGrid from "./landingpage/BoxGrid";
import Button from "./Button";
import Campsite from "./pages/Campsite";
import CampsiteOverview from "./pages/CampsiteOverview";
import Careers from "./pages/Carreers";
import Carousel from "./Carousel";
import Catalog from "./pages/Catalog";
import CatalogTeaser from "./CatalogTeaser";
import Category from "./pages/Category";
import CategoryOverview from "./pages/CategoryOverview";
import Config from "./header/Config";
import ContactTeaser from "./landingpage/ContactTeaser";
import Container from "./Container";
import CycleRoute from "./pages/CycleRoute";
import CycleRouteOverview from "./pages/CycleRouteOverview";
import Experience from "./pages/Experience";
import Experiences from "./pages/Experiences";
import ExperienceSection from "./ExperienceSection";
import Restaurants from "./pages/Restaurants";
import RestaurantTeaser from "./teasers/RestaurantTeaser";
import Email from "./emails/Email";
import Event from "./pages/Event";
import EventOverview from "./pages/EventOverview";
import Faq from "./pages/Faq";
import FeaturedAccommodations from "./FeaturedAccommodations";
import FeaturedCampsites from "./FeaturedCampsites";
import FeaturedCategories from "./FeaturedCategories";
import FeaturedPitches from "./FeaturedPitches";
import Grid from "./Grid";
import Headline from "./typography/Headline";
import ImageGallery from "./landingpage/ImageGallery";
import ImageTeaser from "./teasers/ImageTeaser";
import Job from "./pages/Job";
import JobOverview from "./pages/JobOverview";
import List from "./landingpage/List";
import Map from "./landingpage/Map";
import MenuButton from "./header/MenuButton";
import MenuLink from "./header/MenuLink";
import News from "./pages/News";
import Newsletter from "./pages/Newsletter";
import NewsOverview from "./pages/NewsOverview";
import Offer from "./pages/Offer";
import OfferOverview from "./pages/OfferOverview";
import OfferTeaser from "./teasers/OfferTeaser";
import Office from "./pages/Office";
import OpeningHours from "./landingpage/OpeningHours";
import Contact from "./landingpage/Contact";
import Page from "./Page";
import Pitch from "./pages/Pitch";
import PriceList from "./landingpage/PriceList";
import PriceListItem from "./landingpage/PriceListItem";
import PriceListBoxes from "./landingpage/PriceListBoxes";
import Prices from "./pages/Prices";
import RichText from "./typography/RichText";
import SpecialOpeningHours from "./landingpage/SpecialOpeningHours";
import Stage from "./Stage";
import TabItem from "./landingpage/TabItem";
import Table from "./landingpage/Table";
import TableGrid from "./landingpage/TableGrid";
import Tabs from "./landingpage/Tabs";
import TextImage from "./landingpage/TextImage";
import TextList from "./landingpage/TextList";
import TextListBoxes from "./landingpage/TextListBoxes";
import TextTeaser from "./teasers/TextTeaser";
import Travel from "./landingpage/Travel";
import CustomFallbackComponent from "./CustomFallbackComponent";

const mySchema = cloneDeep(RichTextSchema);

storyblokInit({
  accessToken: process.env.storyblokApiToken,
  use: [apiPlugin],
  apiOptions: {
    region: "eu",
  },
  richText: {
    schema: mySchema,
    resolver: (component, blok) => {
      switch (component) {
        case "table":
          return `<table>
              <thead>
                <tr>
                  ${blok.table.thead
                    .map((th: any) => `<th>${th.value}</th>`.trim())
                    .join("")}
                </tr>
              </thead>
              <tbody>
                ${blok.table.tbody
                  .map((tr: any) =>
                    `
                  <tr>
                    ${tr.body
                      .map((td: any) => `<td>${td.value}</td>`.trim())
                      .join("")}
                  </tr>`.trim()
                  )
                  .join("")}
              </tbody>
            </table>`;
        default:
          return "Resolver not defined";
      }
    },
  },
  components: {
    accommodation_overview: AccommodationOverview,
    accommodation: Accommodation,
    accordion_item: AccordionItem,
    accordion: Accordion,
    award_item: AwardItem,
    iiq_check: IiQCheck,
    awards: Awards,
    reviews: Reviews,
    backlink: Backlink,
    banner_item: BannerItem,
    banner: Banner,
    body_text: BodyText,
    booking: Booking,
    box_grid: BoxGrid,
    box: Box,
    button: Button,
    campsite_overview: CampsiteOverview,
    campsite: Campsite,
    careers: Careers,
    carousel: Carousel,
    catalog_teaser: CatalogTeaser,
    catalog: Catalog,
    category_overview: CategoryOverview,
    category: Category,
    config: Config,
    contact: Contact,
    contact_teaser: ContactTeaser,
    container: Container,
    cycle_route_overview: CycleRouteOverview,
    cycle_route: CycleRoute,
    email: Email,
    event_overview: EventOverview,
    event: Event,
    experience: Experience,
    experiences: Experiences,
    experience_section: ExperienceSection,
    restaurant_teaser: RestaurantTeaser,
    restaurants: Restaurants,
    faq: Faq,
    featured_accommodations: FeaturedAccommodations,
    featured_campsites: FeaturedCampsites,
    featured_categories: FeaturedCategories,
    featured_pitches: FeaturedPitches,
    grid: Grid,
    headline: Headline,
    image_gallery: ImageGallery,
    image_teaser: ImageTeaser,
    job_overview: JobOverview,
    job: Job,
    list_item: AccordionItem,
    list: List,
    map: Map,
    menu_button: MenuButton,
    menu_link: MenuLink,
    news_overview: NewsOverview,
    news: News,
    newsletter: Newsletter,
    offer_overview: OfferOverview,
    offer_teaser: OfferTeaser,
    offer: Offer,
    office: Office,
    opening_hours: OpeningHours,
    page: Page,
    pitch: Pitch,
    price_list_boxes: PriceListBoxes,
    price_list_item: PriceListItem,
    price_list: PriceList,
    prices: Prices,
    rich_text: RichText,
    special_opening_hours: SpecialOpeningHours,
    stage: Stage,
    tab_item: TabItem,
    table_grid: TableGrid,
    table: Table,
    tabs: Tabs,
    text_list_boxes: TextListBoxes,
    text_list: TextList,
    text_image: TextImage,
    text_teaser: TextTeaser,
    travel: Travel,
  },
  enableFallbackComponent: false,
  customFallbackComponent: CustomFallbackComponent,
});

type StoryblokProviderProps = {
  children: ReactElement;
};

export default function StoryblokProvider({
  children,
}: StoryblokProviderProps) {
  return children;
}
