"use client";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useParams, usePathname } from "next/navigation";

const Icon = dynamic(() =>
  import("@/components/icons/Icon").then((module) => module.Icon)
);

const campsites = [
  "bad-duerkheim",
  "bad-kissingen",
  "bernkastel-kues",
  "dorum-nordsee",
  "eckwarderhoerne-nordsee",
  "elbtalaue-bleckede",
  "eschwege",
  "essen-werden",
  "frickenhausen",
  "hamburg",
  "haren",
  "hennesee-meschede",
  "hennesee-meschede",
  "hotel-k1-nohra",
  "huenfeld-praforst",
  "koblenz",
  "lackenhaeuser",
  "leipzig",
  "luebben-spreewald",
  "meppen",
  "mosel-burgen",
  "nassau",
  "nassau",
  "nuernberg",
  "oyten-bremen",
  "ruegen",
  "schloss-langenau",
  "spieka-wattenmeer",
  "viechtach",
  "walkenried",
  "wingst",
];

const LanguageSwitch = () => {
  const { lang } = useParams();
  const pathname = usePathname();
  // Remove existing locale from path (if any)
  const segments = pathname.split("/").filter(Boolean);
  if (["en", "de"].includes(segments[0])) {
    segments.shift();
  }
  const slug = segments.join("/");

  if (!campsites.includes(slug)) {
    return null;
  }

  return (
    <>
      {lang === "de" && (
        <div className="flex items-center gap-2">
          <Icon name="uk" title="English" className="h-3 w-auto" />
          <Link
            href={`/en/${slug}`}
            className="text-sm font-semibold text-gray-900 hover:text-brand-primary100"
          >
            English
          </Link>
        </div>
      )}
      {lang === "en" && (
        <div className="flex items-center gap-2">
          <Icon name="de" title="Deutsch" className="h-3 w-auto" />
          <Link
            href={`/${slug}`}
            className="text-sm font-semibold text-gray-900 hover:text-brand-primary100"
          >
            Deutsch
          </Link>
        </div>
      )}
    </>
  );
};

export { LanguageSwitch };
